<template>
    <v-flex xs12>
        <v-breadcrumbs large :items="routeHistory" divider="/" class="px-0">
            <template v-slot:item="props">
                <v-breadcrumbs-item
                    exact
                    :to="props.item.to"
                    class="breadcrumbs-links text--primary"
                    :class="[props.item.disabled && 'disabled']"
                >
                    {{ props.item.text.charAt(0).toUpperCase()
                    }}{{ props.item.text.substring(1) }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </v-flex>
</template>

<script>
export default {
    name: "Breadcrumbs",
    data() {
        return {
            routeHistory: []
        };
    },
    methods: {
        slugToTitle(slug) {
            const formattedSlug = decodeURI(slug)
                .split("-")
                .join(" ");
            return (
                formattedSlug.charAt(0).toUpperCase() +
                formattedSlug.substring(1)
            );
        }
    },
    watch: {
        $route: {
            handler() {
                let slugName = "";
                if (this.$route.query && this.$route.query.slug) {
                    slugName = ` ${this.slugToTitle(this.$route.query.slug)}`;
                }

                let route = {
                    text: this.$route.meta.name + slugName,
                    exact: true,
                    link: true,
                    to: {
                        name: this.$route.name,
                        query: { ...this.$route.query },
                        meta: { ...this.$route.meta }
                    }
                };

                if (this.routeHistory.some(r => r.text === route.text)) {
                    const existingRouteIndex = this.routeHistory
                        .map(route => route.text)
                        .indexOf(route.text);
                    this.routeHistory.splice(existingRouteIndex, 1);
                    this.routeHistory.push(route);

                    return;
                }

                this.routeHistory.push(route);

                if (this.routeHistory.length > 4) {
                    this.routeHistory.splice(0, 1);
                }
            },
            immediate: false
        }
    }
};
</script>

<style scoped lang="scss">
.disabled {
    color: grey;
    pointer-events: none;
}
</style>

<style lang="scss">
.breadcrumbs-links a {
    transition: all 0.1s linear;

    &:hover {
        color: var(--v-secondary-base) !important;
    }
}
</style>
