import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import ConfirmDialog from "@/plugins/ConfirmDialog/ConfirmDialog";
import "@/scss/admin.module.scss";
import VueMeta from "vue-meta";
import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.common";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

Vue.router = router;

Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        router: Vue.router
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter
    }
});

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(
    ConfirmDialog,
    {
        acceptText: "Da",
        cancelText: "Nu",
        acceptColor: "primary",
        cancelColor: "secondary"
    },
    vuetify
);

Vue.prototype.$eventBus = new Vue();
const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");

export default app;
