<template>
    <v-app id="vue-app">
        <toolbar></toolbar>
        <navigator></navigator>
        <v-main class="gray-bkg-color">
            <breadcrumbs class="ma-5 px-5"></breadcrumbs>
            <transition>
                <transition name="fade" mode="out-in">
                    <router-view class="ma-5"></router-view>
                </transition>
            </transition>
        </v-main>
        <notifier></notifier>
    </v-app>
</template>

<script>
import Navigator from "@/components/Navigator";
import Toolbar from "@/components/Toolbar";
import Notifier from "@/components/Notifier";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
    name: "App",
    metaInfo: {
        title: "",
        titleTemplate: "%s MOL"
    },
    components: {
        Breadcrumbs,
        Notifier,
        Navigator,
        Toolbar
    },
    data: () => ({
        //
    })
};
</script>

<style scoped lang="scss">
.gray-bkg-color {
    background-color: #f3f3f3;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
