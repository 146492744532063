<template>
    <v-navigation-drawer
        dark
        app
        clipped
        color="primary"
        :temporary="$vuetify.breakpoint.smAndDown"
        v-model="drawer"
    >
        <v-list dense nav>
            <v-subheader inset>Meniul</v-subheader>
            <v-list-item
                v-for="item in menuLinks"
                :key="item.title"
                link
                :to="item.to"
                exact
            >
                <v-list-item-icon>
                    <v-icon color="secondary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-list dense nav>
            <v-subheader inset>Primăria</v-subheader>
            <v-list-item
                v-for="item in townHallLinks"
                :key="item.title"
                link
                :to="item.to"
                exact
            >
                <v-list-item-icon>
                    <v-icon color="secondary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="meetings.to" exact v-if="$auth.check()">
                <v-list-item-icon>
                    <v-icon color="secondary">{{ meetings.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ meetings.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="users.to" exact v-if="$auth.check()">
                <v-list-item-icon>
                    <v-icon color="secondary">{{ users.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ users.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: "Navigator",
    mounted() {
        this.$eventBus.$on("toggle-drawer", () => {
            this.drawer = !this.drawer;
        });
    },
    data: () => ({
        drawer: false,
        users: {
            title: "Utilizatori",
            icon: "mdi-account-settings",
            to: {
                name: "users"
            }
        },
        meetings: {
            title: "Materiale ședințe",
            icon: "mdi-account-supervisor-circle",
            to: {
                name: "meetings"
            }
        },
        menuLinks: [
            {
                title: "Meniu Principal",
                icon: "mdi-home",
                to: {
                    name: "dashboard"
                }
            },
            {
                title: "Consiliul Local",
                icon: "mdi-account-group",
                to: {
                    name: "local-council"
                }
            }
        ],
        townHallLinks: [
            {
                title: "Primar",
                icon: "mdi-face",
                to: {
                    name: "mayor"
                }
            },
            {
                title: "Organigrama",
                icon: "mdi-file-tree",
                to: {
                    name: "town-hall"
                }
            },
            {
                title: "Legislație",
                icon: "mdi-file",
                to: {
                    name: "legislation"
                }
            }
        ]
    })
};
</script>
