<template>
    <v-dialog v-model="displayDialog" max-width="800">
        <v-card>
            <v-card-title>
                {{ message }}
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn :color="cancelColor" class="ma-2" @click="choose(false)">
                    {{ cancelText }}
                </v-btn>
                <v-btn :color="acceptColor" class="ma-2" @click="choose(true)">
                    {{ acceptText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "ConfirmDialog",
    props: {
        message: {
            type: String,
            required: true
        },
        acceptText: {
            type: String,
            required: false,
            default: "Yes"
        },
        cancelText: {
            type: String,
            required: false,
            default: "No"
        },
        acceptColor: {
            type: String,
            required: false,
            default: "#123123"
        },
        cancelColor: {
            type: String,
            required: false,
            default: "#321321"
        }
    },
    data: () => ({
        choice: null,
        displayDialog: false
    }),
    mounted() {
        this.displayDialog = true;
    },
    methods: {
        choose(value) {
            this.displayDialog = !this.displayDialog;
            this.choice = value;
            this.$destroy();
        }
    }
};
</script>
