<template>
    <v-app-bar tile app clipped-left :extended="$vuetify.breakpoint.smAndDown">
        <v-app-bar-nav-icon
            @click="$eventBus.$emit('toggle-drawer')"
        ></v-app-bar-nav-icon>
        <v-toolbar-title class="font-weight-bold"
            >Monitorul Oficial Local</v-toolbar-title
        >
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <div class="fill-height d-flex align-center">
                <img height="100%" src="@/assets/stema.jpg" />
                <span class="title">Primăria Municipiului Gherla</span>
            </div>

            <v-spacer></v-spacer>
            <v-toolbar-title class="font-weight-bold">
                <v-btn v-if="$auth.check()" @click="logOut" text>
                    <v-icon class="mr-1" small>mdi-logout</v-icon>
                    Delogare
                </v-btn>
                <v-btn v-else to="/login" text>
                    <v-icon class="mr-1" small>mdi-signin</v-icon>
                    Logare
                </v-btn>
            </v-toolbar-title>
        </template>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
            <div class="fill-height d-flex align-center pb-2">
                <img height="100%" src="@/assets/stema.jpg" />
                <span class="title">Primăria Municipiului Gherla</span>
            </div>
            <v-toolbar-title class="font-weight-bold">
                <v-btn v-if="$auth.check()" @click="logOut" text>
                    <v-icon class="mr-1" small>mdi-logout</v-icon>
                    Delogare
                </v-btn>
                <v-btn v-else to="/login" text>
                    <v-icon class="mr-1" small>mdi-signin</v-icon>
                    Logare
                </v-btn>
            </v-toolbar-title>
        </template>
    </v-app-bar>
</template>

<script>
export default {
    name: "Toolbar",
    methods: {
        async logOut() {
            await this.$auth.logout({
                makeRequest: true,
                redirect: "/"
            });
        }
    }
};
</script>
