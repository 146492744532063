import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ro from "vuetify/lib/locale/ro";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
Vue.prototype.$vuetifyTableItemsPerPage = [1, 5, 10, 15, 20, 30, 50, 100, 200];

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#012046",
                secondary: "#F65218",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
    lang: {
        locales: { ro },
        current: "ro"
    },
    icons: {
        iconfont: "mdi"
    }
});
