<template>
    <v-snackbar
        :timeout="notifier.messageTimeout"
        v-model="notifier.active"
        :color="notifier.color"
        top="top"
    >
        {{ notifier.msg }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                icon
                role="button"
                v-bind="attrs"
                aria-label="Închide alerta"
                @click="notifier.active = false"
            >
                &times;
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "Notifier",
    data() {
        return {
            notifier: {
                color: "success",
                msg: "",
                messageTimeout: -1,
                active: false,
                timeout: null
            }
        };
    },
    created() {
        this.$eventBus.$on("notify", this.showMsg);
    },
    methods: {
        showMsg(msg, color = "success") {
            if (this.timeout) {
                this.notifier.active = false;
                clearTimeout(this.timeout);
            }
            this.notifier.msg = msg;
            this.notifier.color = color;
            this.notifier.active = true;
            this.timeout = setTimeout(() => {
                this.notifier.active = false;
            }, 7000);
        }
    }
};
</script>
