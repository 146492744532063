import ConfirmDialogComponent from "./ConfirmDialog.vue";

const ConfirmVuetifyPlugin = {
    install(Vue, options, vuetify) {
        Vue.prototype.$confirm = (
            dialogProps,
            component = ConfirmDialogComponent
        ) =>
            new Promise(resolve => {
                options = Object.assign(options, dialogProps);
                const app = document.getElementById("vue-app");
                const dialog = Vue.extend(component);
                const instance = new dialog({
                    propsData: options,
                    destroyed() {
                        app.removeChild(instance.$el);
                        resolve(instance.choice);
                    },
                    vuetify
                });

                instance.$mount();
                app.appendChild(instance.$el);
            });
    }
};

export default ConfirmVuetifyPlugin;
