"use strict";

import Vue from "vue";
import axios from "axios";
import app from "@/main.js";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common["Authorization"] = "";
axios.defaults.headers.post["Content-Type"] = "application/json";

let config = {
    baseURL: process.env.VUE_APP_BASE_URL || "https://mol.floresticluj.ro/api",
    withCredentials: true
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        if (response.data && response.data.message) {
            app.$eventBus.$emit("notify", response.data.message);
        }
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        if (error.response.data && error.response.data.message) {
            app.$eventBus.$emit("notify", error.response.data.message, "error");
        }
        if (error.response.data && error.response.data.errors) {
            app.$eventBus.$emit("display-errors", error.response.data.errors);
        }
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
